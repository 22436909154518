export function FirstStepForm(values) {
    let errors = {};
    console.log("confirmPassword", values)
    if (values.cust_name === '') {
        errors.cust_name = 'Name is required';
    }
    // if (values.contact === '') {
    //     errors.contact = 'Contact is required';
    // }
    if (values.countryCode === '' || values.contact === '') {
        errors.countryCode = 'Country Code & Contact is required';
    }
    if (values.company_name === '') {
        errors.company_name = 'Company Name is required';
    }
    if (values.company_size === '') {
        errors.company_size = 'Company Size is required';
    }
    if (values.email === '') {
        errors.email = 'Email address is required';
    }
    if (values.signupemail === '') {
        errors.signupemail = 'Email address is required';
    }
    if (values.forgotemail === '') {
        errors.forgotemail = 'Email is required';
    }
    if (values.otp === '') {
        errors.otp = 'OTP is required';
    }
    if (values.password === '') {
        errors.password = 'Password is required';
    }
    if (values.signuppassword === '') {
        errors.signuppassword = 'Password is required';
    }
    if (values.newPassword === '') {
        errors.newPassword = 'New Password is required';
    }
    if (values.confirmPassword === '') {
        errors.confirmPassword = 'Confirm Password is required';
    }
    
    console.log("FirstStepForm", errors)
    return errors;
};


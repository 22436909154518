import { useState, useEffect } from 'react';
// import AuthAction from '../Redux/action/AuthAction';
// import { useSelector, useDispatch } from 'react-redux'


const useFormvalidation = (callback, validate, formValues) => {
    // const dispatch = useDispatch()
    // const { countryCode } = AuthAction();

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [imgData, setImgData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        setValues(formValues);
    }, [formValues]);

    // console.log("formValues", formValues)
    useEffect(() => {
        // console.log("etgrg", errors)
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    const handleChange = (event) => {
        event.persist();
            if(event.target.name === "cust_name"){
                setErrors(errors => ({...errors, [event.target.name] : ""}))
            }else if(event.target.name === "contact"){
                setErrors(errors => ({...errors, countryCode : ""})) 
            }else if(event.target.name === "signupemail"){
                setErrors(errors => ({...errors, [event.target.name] : ""})) 
            }else if(event.target.name === "signuppassword"){
                setErrors(errors => ({...errors, [event.target.name] : ""})) 
            }else if(event.target.name === "company_name"){
                setErrors(errors => ({...errors, [event.target.name] : ""})) 
            }else if(event.target.name === "company_size"){
                setErrors(errors => ({...errors, [event.target.name] : ""})) 
            }
            
            setValues(values => ({ ...values, [event.target.name]: event.target.value }));
        // }
    };


    const handlePictureChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.files[0] }));
        setImgData({
            ...imgData,
            [event.target.name]: URL.createObjectURL(event.target.files[0]),
        });
    };
    return {
        handleChange,
        handlePictureChange,
        handleSubmit,
        imgData,
        values,
        setErrors,
        errors,

    }
};

export default useFormvalidation;
const initialState = {
  checkStatus: 0,
  forgotStatus: 0,
  firstStepForm: {
    cust_name: "",
    signupemail: "",
    signuppassword: "",
    contact: "",
    countryCode: "",
    company_name:"",
    company_size:"",
    terms_policy:0,
  },
  loginForm:{
    email: "",
    password: "",
  },
  forgotVarification: {
    forgotemail: "",
  },
  updateForm: {
    newPassword: "",
    confirmPassword: "",
  },
  formData:{},
  verificationStatus:{},
  setotp_msz:"",
  otp_status: false,
  otpStatus:{},
  favicon:"",
  logowhite:"",
  logo:"",
  color:"",
  source:"",
  token:"",
  loginError:"",
  loaderstatus: false,
  moduleData : {},
  selectedModules:[],
  vendorOrderData:{},
  calculation:{
    total: 0,
    tax: 0,
    grandTotal: 0,
  },
  errorMsz:{},
  vendorData:{},
  callBackRediectUrl:"",
  countryData:null,

  masterData:{},
  subscriptionData: null,
  subfilterdata: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setcheckStatus':
      return {
        ...state,
        checkStatus: action.payload
      };
    case 'setforgotStatus':
      return {
        ...state,
        forgotStatus: action.payload
      };
    case 'setSelectedModules':
      return {
        ...state,
        selectedModules: action.payload
      };
    case 'setfirstStepForm':
      return {
        ...state,
        firstStepForm: action.payload
      };
    case 'setforgotVarification':
      return {
        ...state,
        forgotVarification: action.payload
      };
    case 'setformData':
      return {
        ...state,
        formData: action.payload
      };
    case 'setVerification_status':
      return {
        ...state,
        verificationStatus: action.payload
      };
    case 'setOtpStatus':
      return {
        ...state,
        OtpStatus: action.payload
      };
    case 'setotp_msz':
        return {
          ...state,
          otp_msz: action.payload
        };
    case 'setotp_status':
        return {
          ...state,
          otp_status: action.payload
        };
    case 'setfavicon':
        return {
          ...state,
          favicon: action.payload
        };
    case 'setlogowhite':
        return {
          ...state,
          logowhite: action.payload
        };
    case 'setlogo':
        return {
          ...state,
          logo: action.payload
        };
    case 'setSource':
        return {
          ...state,
          source: action.payload
        };
    case 'setToken':
        return {
          ...state,
          token: action.payload
        };
    case 'setloginError':
        return {
          ...state,
          loginError: action.payload
        };
    case 'setloaderstatus':
        return {
          ...state,
          loaderstatus: action.payload
        };
    case 'setupdateForm':
        return {
          ...state,
          updateForm: action.payload
        };
    case 'setloginForm':
        return {
          ...state,
          loginForm: action.payload
        };
    case 'setmoduleData':
        return {
          ...state,
          moduleData: action.payload
        };
    case 'setvendorOrderData':
        return {
          ...state,
          vendorOrderData: action.payload
        };
    case 'setCalculation':
        return {
          ...state,
          calculation: action.payload
        };
    case 'seterrorMsz':
        return {
          ...state,
          errorMsz: action.payload
        };
    case 'setcountryData':
        return {
          ...state,
          countryData: action.payload
        };
   


  default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import logo from "../images/shopersbay_logo.png";
import { useSelector, useDispatch } from "react-redux";
import AuthAction from "../Redux/action/AuthAction";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaCheckCircle, FaCheck } from "react-icons/fa";
import { MdOutlineCircle } from "react-icons/md";
import { useNavigate, useSearchParams,NavLink } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';


const Module = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { moduleList, fetchIp } = AuthAction();
    const [searchParams] = useSearchParams();
    const param = searchParams.get("q");
    const { moduleData, selectedModules, calculation, vendorOrderData, countryData } = useSelector(
        (state) => state.authdetail
    );
    const [currency, setCurrency] = useState("");
    const [duration, setDuration] = useState("month");
    const [selectedModuleIds, setSelectedModuleIds] = useState([]);
   
    console.log("buihiuh", countryData)
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const plan = urlParams.get("planType");
        if (plan) {
            setDuration(plan);
        }
        const data = urlParams.get("data");
        const ids = data ? data.split(",") : [];
        setSelectedModuleIds(ids);

    }, []);

    useEffect(() => {
        if (Array.isArray(moduleData)) {
            const selectedModules = moduleData.flatMap((module) =>
                module.child_list.filter((child) => selectedModuleIds.includes(child.id))
            );

            if (selectedModules.length > 0) {
                dispatch({ type: "setSelectedModules", payload: selectedModules });
                setCurrency(selectedModules[0].currency);
                updateCalculation(selectedModules);
            }
        }
    }, [moduleData]);

    useEffect(() => {

        if(countryData != null){
            dispatch(moduleList({ duration, duration_type: "free_trial", countryCode: countryData }));
        }

        
    }, [dispatch, duration, countryData]);

    const handleChange = (e) => {
        const newDuration = e.target.checked ? "annual" : "month";
        setDuration(newDuration);
        dispatch(moduleList({ duration: newDuration, duration_type: "free_trial" }));
        dispatch({
            type: "setvendorOrderData",
            payload: { ...vendorOrderData, duration: newDuration },
        });
        updateCalculation(selectedModules); // Keep the selected modules same
    };

    const handleCheckboxChange = (moduleData) => {
        const isModuleSelected = selectedModules.some((item) => item.id === moduleData.id);
        const updatedModules = isModuleSelected
            ? selectedModules.filter((item) => item.id !== moduleData.id)
            : [...selectedModules, moduleData];

        setCurrency(moduleData.currency);
        dispatch({ type: "setSelectedModules", payload: updatedModules });
        updateCalculation(updatedModules);

        // Update URL to reflect selected/deselected modules
        updateUrlWithSelectedModules(updatedModules);
    };

    const updateCalculation = (modules) => {
        const total = modules.reduce((sum, { price }) => sum + parseFloat(price), 0).toFixed(2);
        const totalTax = modules
            .reduce((sum, { price, tax_percent }) => sum + parseFloat(price) * (parseFloat(tax_percent) / 100), 0)
            .toFixed(2);
        const grandTotal = (parseFloat(total) + parseFloat(totalTax)).toFixed(2);

        dispatch({
            type: "setCalculation",
            payload: { total, tax: totalTax, grandTotal },
        });

        const selectedIds = modules.map((item) => item.module);
        dispatch({
            type: "setvendorOrderData",
            payload: {
                ...vendorOrderData,
                sub_total: total,
                tax: totalTax,
                total_amount: grandTotal,
                selected_modules: selectedIds.join(", "),
                item_count: selectedIds.length,
                duration,
                duration_type: "free_trial",
            },
        });
    };

    const updateUrlWithSelectedModules = (updatedModules) => {
        const selectedIds = updatedModules.map((item) => item.id);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("data", selectedIds.join(","));
        navigate({ search: urlParams.toString() }, { replace: true });
    };

    const handleContinue = () => {
        navigate(`/signup?q=${param}`);
    };

    

    return (
        <div className="module-section">
            <Container>
                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <Card className="border-0 p-4 parent-card">
                            <div className="mb-2">
                                <NavLink to="https://shopersbay.com/">
                                    <img src={logo} alt="ShopersBay Logo" />
                                </NavLink>
                            </div>
                            <h5 className="mb-4">Get Started with your 15-day free trial.</h5>
                            <Row style={{ minHeight: moduleData?.length ? "" : "350px", position: "relative" }}>
                                <Col lg={selectedModules.length > 0 ? 8 : 12}>
                                    <Row>
                                        {Array.isArray(moduleData) && moduleData.length > 0 ? (
                                            moduleData.map((ele, i) =>
                                                ele.child_list.map((data, ind) => {
                                                    const isSelected = selectedModules.some((item) => item.id === data.id);
                                                    return (
                                                        <label className="col-lg-6 col-md-6 col-6 form-label" key={`${i}-${ind}`}>
                                                            <div
                                                                className={`card d-flex w-100 align-items-center flex-row p-3 ${isSelected ? "selected" : ""}`}
                                                                onClick={() => handleCheckboxChange(data)}
                                                            >
                                                                <div className="module_name mx-2 flex-grow-1">
                                                                    <h6>{data.module_label}</h6>
                                                                </div>
                                                                <div className="checkbox-circle">
                                                                    {isSelected ? (
                                                                        <FaCheckCircle fill="#00A1F1" size={20} />
                                                                    ) : (
                                                                        <MdOutlineCircle fill="#ADB5BD" size={22} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </label>
                                                    );
                                                })
                                            )
                                        ) : (
                                            <Spinner animation="border" />
                                        )}
                                    </Row>
                                </Col>

                                {selectedModules.length > 0 && (
                                    <Col lg={4}>
                                        <Card className="border-0">
                                            <Card.Body>
                                                <div className="toggle-section mb-3">
                                                    <div className="ot-switcher">
                                                        <span className="l-switch">Monthly</span>
                                                        <label className="switch form-check-label">
                                                            <input
                                                                type="checkbox"
                                                                onChange={handleChange}
                                                                checked={duration === "annual"}
                                                                style={{ opacity: 0 }}
                                                            />
                                                            <span
                                                                className={`slider round ${duration === "annual" ? "activeclass activebg" : "inactiveclass inactivebg"
                                                                    }`}
                                                            ></span>
                                                        </label>
                                                        <span className="r-switch">Annually</span>
                                                    </div>
                                                </div>
                                                <h6>
                                                    <b>{selectedModules.length}</b> {selectedModules.length > 1 ? "Apps" : "App"} Selected
                                                </h6>
                                                <ul className="list-unstyled mb-3">
                                                    {selectedModules.map((module, idx) => (
                                                        <li key={idx} className="d-flex justify-content-between selecteddataa">
                                                            <div>
                                                                <FaCheck fill="#00A1F1" size={10} /> {module.module_label} {
                                                                    duration === 'annual' &&
                                                                    <span className='selected_discount'>{module.annual_discount}%off</span>
                                                                }
                                                            </div>
                                                            <div>
                                                                {module.currency} {(parseFloat(module.price) + (parseFloat(module.price) * module.tax_percent / 100)).toFixed(2)}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="mb-3 small">
                                                    <Row>
                                                        <Col lg={6} md={6} xs={6}>Subtotal</Col>
                                                        <Col lg={6} md={6} xs={6} className="text-end">
                                                            {currency} {calculation.total}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6} md={6} xs={6}>Total Tax</Col>
                                                        <Col lg={6} md={6} xs={6} className="text-end">
                                                            {currency} {calculation.tax}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6} md={6} xs={6}>Total</Col>
                                                        <Col lg={6} md={6} xs={6} className="text-end">
                                                            {currency} {calculation.grandTotal}
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="text-end">
                                                    <button
                                                        type="button"
                                                        className="octf-btn"
                                                        onClick={handleContinue}
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                            <div className='text-center d-flex justify-content-center bottomlink'>
                                <span className='account-text'>Already Have an account?</span>&nbsp;
                                <div className="signin_pricepage" onClick={() => navigate("/shopersbay")}> Sign In</div>

                            </div>
                        </Card>
                    </Col>
                    <Col lg={2}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default Module;

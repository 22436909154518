import React from 'react'
import Apis from "../../services/Apis";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie';import axios from 'axios';


const AuthAction = () => {
  const { getIp, vendorCreation, saveCustomer, LoginService, checkEmail, verifyotpService, forgotPasswordApi, updatePasswordApi, getCountryCode, getModuleList } = Apis()
  const { formData, selectedModules, firstStepForm, calculation, vendorOrderData, errorMsz } = useSelector(state => state.authdetail);
  const navigate = useNavigate();
  const location = useLocation();
  var cookies = new Cookies();
  const [searchParams] = useSearchParams();

  var q_param = searchParams.get('q');

  //create customer
  const createCustomer = (input_data, param) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await saveCustomer(input_data);

    if (response.status == 200) {
      


      var userData = {
        agency_name: firstStepForm.company_name,
        username: formData.email,
        password: formData.password,
        poc_phone_no: firstStepForm.contact,
        currency_country: firstStepForm.countryCode,
        company_size: firstStepForm.company_size,
        customer_id: response.data.customer_id,
        name: firstStepForm.cust_name,
        terms_policy: firstStepForm.terms_policy,
      }
      dispatch(vendorCreate(userData, param))

    }

  };
  //login function
  const userlogin = (input_data, slug) => async dispatch => {
    dispatch({
      type: 'setloaderstatus',
      payload: true,
    });
    const response = await LoginService(input_data);
    if (response.data.status == "success") {
      if (response.data.login_type === "user") {
          window.location.href = `https://admin.shopersbay.com/welcomepage/?jwt=${response.data.jwt_token}&source=${slug}`;
        // dispatch({
        //   type: 'setfirstStepForm',
        //   payload: {
        //     email: "",
        //     password: "",
        //   },
        // });
      } else if (response.data.login_type === "customer") {
        cookies.set('jwt', response.data.jwt_token)

      }
      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
    } else {
      dispatch({
        type: 'setloginError',
        payload: response.data.msg,

      });
      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
    }
  };
  //email varification for sign up
  const varifyEmail = (input_data) => async dispatch => {
    // dispatch(getSubscription())
    dispatch({
      type: 'setloaderstatus',
      payload: true,
    });
    const response = await checkEmail(input_data);
    if (response.data.status == 'error') {
      dispatch({
        type: 'setVerification_status',
        payload: response.data,

      });
      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
    } else {
      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
      dispatch({
        type: 'setcheckStatus',
        payload: 1,

      });
      dispatch({
        type: 'setVerification_status',
        payload: {},

      });
      localStorage.setItem('usid', input_data.email)
      localStorage.setItem('psid', input_data.password)
      dispatch({
        type: 'setformData',
        payload: { ...formData, "id": response.data.data, "email": input_data.email, "password": input_data.password },

      });
    }
  };
  //otp varification
  const varifyOtppassword = (input_data, param) => async dispatch => {

    dispatch({
      type: 'setloaderstatus',
      payload: true,
    });
    const response = await verifyotpService(input_data);

    if (response.status == 200) {
      if (response.data.status == "success") {
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
        dispatch({
          type: 'setotp_status',
          payload: true
        });

        dispatch({
          type: 'setotp_msz',
          payload: response.data.message
        });
        dispatch({
          type: 'setOtpStatus',
          payload: {},

        });
        if (location.pathname === '/forgot-password') {
          dispatch({
            type: 'setforgotStatus',
            payload: 2,

          });
        } else {
          var input_data1 = {
            email: formData.email,
            password: formData.password,
            name: "",
            category: 2,
            gst: "",
            cin: "",
            address: "",
            state: "",
            pincode: ""
          }

          dispatch(createCustomer(input_data1, param))
        }



      } else {
        dispatch({
          type: 'setloaderstatus',
          payload: false,
        });
        dispatch({
          type: 'setotp_msz',
          payload: response.data.message
        });
        dispatch({
          type: 'setotp_status',
          payload: false
        });
        dispatch({
          type: 'setOtpStatus',
          payload: response.data,

        });
      }
    }

  };
  //email varify for forgot password
  const forgotPassword = (input_data) => async dispatch => {
    dispatch({
      type: 'setloaderstatus',
      payload: true,
    });
    const response = await forgotPasswordApi(input_data);
    
    if (response.data.status === 'error') {
      dispatch({
        type: 'seterrorMsz',
        payload: { ...errorMsz, forgotPassword: "Please Enter Valid email" } // Corrected payload key
      });
      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
      dispatch({
        type: 'setVerification_status',
        payload: response.data,

      });
      dispatch({
        type: 'setloginError',
        payload: response.data.msg,

      });
      
    } else {
      dispatch({
        type: 'setloginError',
        payload: "",

      });
      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
      dispatch({
        type: 'setforgotStatus',
        payload: 1,
      });
      dispatch({
        type: 'setVerification_status',
        payload: {},

      });
      dispatch({
        type: 'setformData',
        payload: { ...formData, "id": response.data.data, "type": response.data.login_type, "email": input_data.email },

      });
    }
  };
  //password update
  const updatePassword = (input_data, data) => async dispatch => {
    dispatch({
      type: 'setloaderstatus',
      payload: true,
    });
    const response = await updatePasswordApi(input_data);
    if (response.data.status == 'error') {

      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
    } else {
      dispatch({
        type: 'setloaderstatus',
        payload: false,
      });
      dispatch({
        type: 'setforgotStatus',
        payload: 0,
      });
      if (data != "") {
        navigate(`/${data}`);
      } else {
        navigate(`/`);
      }
    }
  };
  //create vendor
  const vendorCreate = (input_data, param) => async dispatch => {
    const response = await vendorCreation(input_data);
    if (response.data.status == 'success') {
      // dispatch(createVendorOrder())
      window.location.href = `https://admin.shopersbay.com/welcomepage/?jwt=${response.data.jwt_token}&source=shopersbay&regtype=free_trial&modules=${vendorOrderData.selected_modules}`;
    } else {

    }
  };

  const countryCode = (input_data, values, setErrors, errors) => async dispatch => {
    const response = await getCountryCode(input_data);
    if (response.data.status == 'success') {
      dispatch({
        type: 'setfirstStepForm',
        payload: { ...values, countryCode: response.data.data.id },
      });
      
      setErrors(errors => ({...errors, countryCode : ""}))
    } else {
      setErrors(errors => ({...errors, countryCode : response.data.message}))
    }
  };

  const moduleList = (input_data) => async dispatch => {
    const response = await getModuleList(input_data);
    if (response.data.status == 'success') {
      
      dispatch({
        type: 'setmoduleData',
        payload: response.data.menu_list,
      });

      if(selectedModules.length > 0){
        const selectedIds = selectedModules.map(item => item.id);
        const module = selectedModules.map(item => item.module);

        
        const filteredMenuList = response.data.menu_list.filter(menu =>
          menu.child_list.some(child => selectedIds.includes(child.id))
        );

        const result = filteredMenuList.map((ele) =>
          ele.child_list.filter((data) =>
            selectedIds.includes(data.id)
          )
        ).flat();

        const total = result.reduce((sum, { price }) => sum + parseFloat(price), 0).toFixed(2);
        const totalTax = result.reduce((sum, { price, tax_percent }) => sum + parseFloat(parseFloat(price) * parseFloat(tax_percent)) / 100, 0).toFixed(2);

        const grandTotal = result.reduce((sum, { price, tax_percent }) => {
            const numericPrice = parseFloat(price);
            const numericTaxPercent = parseFloat(tax_percent);
            const taxAmount = (numericPrice * numericTaxPercent) / 100;
            const totalForItem = numericPrice + taxAmount;
            return sum + totalForItem;
        }, 0);
        const grand = grandTotal.toFixed(2);


        
        dispatch({
          type: 'setSelectedModules',
          payload: result,
        });

        const dataCalculated = {...calculation, total: total, tax: totalTax, grandTotal: grand};

        dispatch({
            type: "setCalculation",
            payload: dataCalculated
        })

        dispatch({
            type: "setvendorOrderData",
            payload: {...vendorOrderData, sub_total: total, tax: totalTax, total_amount: grand, selected_modules: module.join(', '), item_count: module.length, vendor_id: 0}
        })


        
      }

    } else {
    }
  };

  const getIpCode = () => async dispatch => {
    const response = await getIp();
    console.log(response, "wefreargf")
    if (response.data.status == 'success') {
      dispatch({
        type: 'setcountryData',
        payload: response.data,
      });
      
    } else {
    }    
  };


  return {
    userlogin: userlogin,
    varifyEmail: varifyEmail,
    createCustomer: createCustomer,
    varifyOtppassword: varifyOtppassword,
    forgotPassword: forgotPassword,
    updatePassword: updatePassword,
    vendorCreate: vendorCreate,
    countryCode: countryCode,
    moduleList: moduleList,
    getIpCode: getIpCode,
  }
}

export default AuthAction
